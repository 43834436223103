<template>
<div>
    <div class="text-right mb-1">
        <b-button
            variant="primary"
            pill
            @click="createFAQ"
            v-if="hasPermission('create_faq')"
        >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Create</span>
        </b-button>
    </div>

    <b-card>
<!-- Filters Below  -->
        <b-row class="mb-1" align-v="center">
            <b-col md="7">
            <div v-if="searchType">
                <b-form-group
                label="Question"
                label-for="question"
                class="w-50"
                v-if="searchType.value === 1"
                >
                <b-form-input
                    id="question"
                    v-model="question"
                    placeholder="Question"
                />
                </b-form-group>
                
            </div>
            </b-col>
            <b-col md="3">
            <b-form-group label="Search Type" label-for="searchType">
                <v-select
                id="searchType"
                v-model="searchType"
                :options="searchTypes"
                placeholder="Search Type"
                label="name"
                />
            </b-form-group>
            </b-col>
            <b-col md="2">
            <b-button variant="primary" pill @click="search">
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span class="align-middle">Search</span>
            </b-button>
            </b-col>
        </b-row>
      <!-- Filters above  -->

        <b-table
            responsive="sm"
            :fields="fields"
            :items="faqs"
            details-td-class="m-0 p-0"
            small
            :per-page="perPage"
        >
            <template #cell(serial)="row">
                {{ (currentPage - 1) * perPage + row.index + 1 }}
            </template>
            <template #cell(question)="row">
                {{ truncate(row.item.question, 20) }}
            </template>
            <template #cell(category_name)="row">
                {{ truncate(row.item.category_name, 20) }}
            </template>
            <template #cell(created_at)="row">
                {{ row.item.created_at }}
            </template>
            <template #cell(updated_at)="row">
                {{ row.item.updated_at }}
            </template>
            <template #cell(created_by_data)="row">
                {{ row.item.created_by }}
            </template>
            <template #cell(updated_by_data)="row">
                {{ row.item.updated_by }}
            </template>
            <template #cell(manage)="row">
                <b-button
                    variant="info"
                    pill
                    size="sm"
                    class="mr-1"
                    @click="editFAQ(row.item)"
                    v-if="hasPermission('update_faq')"
                >
                    Edit
                </b-button>
                <b-button
                    variant="danger"
                    pill
                    size="sm"
                    @click="removeFAQ(row.item)"
                    v-if="hasPermission('delete_faq')"
                >
                    Delete
                </b-button>
            </template>
        </b-table>
        <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
        ></b-pagination>
    </b-card>
    <FAQCreateModal
        @modalClosed="onModalClosed"
        :key="`create-${faqCreateModalCount}`"
    />
    <FAQEditModal
        :faq="faq"
        @modalClosed="onModalClosed"
        :key="`edit-${faqEditModalCount}`"
    />
</div>
</template>
    
  
<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import FAQCreateModal from "@/components/admin/faq/FAQCreateModal.vue";
import FAQEditModal from "@/components/admin/faq/FAQEditModal.vue";

export default {
    components: {
        FAQCreateModal,
        FAQEditModal,
    },
    data() {
        return {
            fields: [
                { key: "serial", label: "S. No" },
                { key: "question", label: "Question" },
                { key: "answer", label: "Answer" },
                { key: "created_at", label: "Created At" },
                { key: "created_by_data", label: "Created By" },
                { key: "updated_at", label: "Updated At" },
                { key: "updated_by_data", label: "Updated By" },
                { key: "manage", label: "Action" },
            ],
            currentPage: 1,
            perPage: 10,
            totalItems: 0,
            faq: null,
            faqs: [],
            faqCategories: [],
            faqCreateModalCount: 0,
            faqEditModalCount: 0,
            searchTypes: [
                { value: 1, name: "Question" },
            ],
            searchType: null,
            question: "",
        };
    },
    mixins: [util],
    created() {
        this.searchType = this.searchTypes[0];
    },
    async mounted() {
        await this.fetchPaginatedData();
        await this.fetchFAQCategories(); 
    },
    methods: {
        ...mapActions({
            getFAQs: "appData/getFAQ",
            deleteFAQ: "appData/deleteFAQ",
            getFAQCategories: "appData/getFAQPublic",
        }),
        async search() {
            if (this.searchType) {
                switch (this.searchType.value) {
                    case 1:
                        break;
                }
            } else {
                this.question = "";
            }
            this.currentPage = 1;
            await this.fetchPaginatedData();
        },
        async fetchFAQCategories() {
            try {
                const res = await this.getFAQCategories();
                this.faqCategories = res.data;
            } catch (error) {
                console.error("Error fetching FAQ categories:", error);
            }
        },
        async fetchPaginatedData() {
            try {
                const res = await this.getFAQs({
                    pageNumber: this.currentPage,
                    question: this.question ? this.question : "",
                   
                });
                this.faqs = res.data.results;
                this.totalItems = res.data.count;
                this.perPage = res.data.per_page;
            } catch (error) {
                this.displayError(error);
            }
        },
        createFAQ() {
            this.faqCreateModalCount += 1;
            this.$nextTick(() => {
                this.$bvModal.show("faq-create-modal");
            });
        },
        editFAQ(faq) {
            this.faq = faq;
            this.faqEditModalCount += 1;
            this.$nextTick(() => {
                this.$bvModal.show("faq-edit-modal");
            });
        },
        async removeFAQ(faq) {
            this.faq = faq;
            this.$swal({
                title: "Are you sure?",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: "Confirm",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    try {
                        const res = await this.deleteFAQ({ pk: this.faq.id });
                        if (res.status === 200) {
                            this.$swal({
                                title: "FAQ deleted successfully",
                                icon: "success",
                            });
                            if (
                                (this.totalItems - 1) % this.perPage === 0 &&
                                this.currentPage !== 1
                            ) {
                                this.currentPage -= 1;
                            } else {
                                await this.fetchPaginatedData();
                            }
                        }
                    } catch (error) {
                        console.error("Error deleting FAQ:", error);
                    }
                }
            });
        },
        async onModalClosed() {
            await this.fetchPaginatedData();
        },
    },
    computed: {
        ...mapGetters({
            hasPermission: "appData/hasPermission",
        }),
    },
    watch: {
        currentPage: async function (val) {
            await this.fetchPaginatedData();
        },
    },
};
</script>

  
  <style>
  .bg1 {
    color: red;
    font-weight: normal;
  }
  </style>
  