<template>
<b-modal
    id="faq-create-modal"
    title="FAQ Create Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
>
    <template #modal-title>
    <h2 class="m-0">FAQ Create</h2>
    </template>
    <validation-observer ref="faqCreateFormValidation">
    <b-form @submit.prevent="validationForm">
        <b-form-group label-for="question">
        <template #label>
            Question <span class="text-danger">*</span>
        </template>
            <b-form-input
            id="question"
            v-model="question"
            placeholder="Enter the question"
            required
            @keydown="checkMaxLength($event, 'question', 400)"
            />
        </b-form-group>

        <b-form-group label-for="answer">
        <template #label>
            Answer <span class="text-danger">*</span>
        </template>
        <b-form-textarea
            id="answer"
            v-model="answer"
            placeholder="Enter the answer"
            rows="6"
            required
        />
        </b-form-group>

        <b-form-group label-for="status">
        <template #label>
            Status <span class="text-danger">*</span>
        </template>
        <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
        ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
        <b-button type="submit" variant="primary" pill class="mr-1">
            Save
        </b-button>
        <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            pill
        >
            Reset
        </b-button>
        </b-form-group>
    </b-form>
    </validation-observer>
</b-modal>
</template>
  
  <script>
  import { mapActions } from "vuex";
  import { required } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { BButton, BForm, BFormGroup, BFormInput, BFormTextarea, BFormSelect } from "bootstrap-vue";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BFormSelect,
    },
    data() {
      return {
        required,
        question: "",
        answer: "",
        status: "",
        options: [
          { value: "", text: "-- Select --" },
          { value: true, text: "Enable" },
          { value: false, text: "Disable" },
        ],
      };
    },
    methods: {
      ...mapActions({
        createFAQ: "appData/createFAQ",
      }),
      checkMaxLength(event, fieldName, maxLength) {
        if (
          event.target.value.length > maxLength &&
          event.inputType !== "deleteContentBackward" &&
          event.inputType !== "deleteContentForward"
        ) {
          event.preventDefault();
          this[fieldName] = event.target.value.slice(0, maxLength);
        } else {
          this[fieldName] = event.target.value;
        }
      },
      async validationForm() {
        const success = await this.$refs.faqCreateFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
          const formData = {
            question: this.question,
            answer: this.answer,
            status: this.status,
          };
          const res = await this.createFAQ(formData);
          if (res.status === 200) {
            this.$swal({
              title: "FAQ created successfully",
              icon: "success",
            });
            this.reset();
            this.$nextTick(() => {
              this.$bvModal.hide("faq-create-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      reset() {
        this.question = "";
        this.answer = "";
        this.status = ""; 
      },
    },
  };
  </script>
  
  <style></style>
  