<template>
<b-modal
    id="faq-edit-modal"
    title="FAQ Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
>
    <template #modal-title>
    <h2 class="m-0">FAQ Edit</h2>
    </template>
    <validation-observer ref="faqEditFormValidation">
    <b-form @submit.prevent="validationForm">
        <b-form-group label-for="question">
        <template #label>
            Question <span class="text-danger">*</span>
        </template>
            <b-form-input
            id="question"
            v-model="question"
            placeholder="Enter the question"
            required
            @keydown="checkMaxLength($event, 'question', 400)"
            />
        </b-form-group>

        <b-form-group label-for="answer">
        <template #label>
            Answer <span class="text-danger">*</span>
        </template>
            <b-form-textarea
            id="answer"
            v-model="answer"
            placeholder="Enter the answer"
            rows="6"
            required
            />
        </b-form-group>

        <b-form-group label-for="status">
        <template #label>
            Status <span class="text-danger">*</span>
        </template>
        <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
        ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
        <b-button type="submit" variant="info" pill class="mr-1">
            Update
        </b-button>
        </b-form-group>
    </b-form>
    </validation-observer>
</b-modal>
</template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { required } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { BButton, BForm, BFormGroup, BFormInput, BFormTextarea, BFormSelect } from "bootstrap-vue";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BFormSelect,
    },
    props: {
      faq: Object,
    },
    data() {
      return {
        required,
        question: "",
        answer: "",
        status: "",
        options: [
          { value: "", text: "-- Select --" },
          { value: true, text: "Enable" },
          { value: false, text: "Disable" },
        ],
      };
    },
    async mounted() {
      if (this.faq) {
        this.question = this.faq.question;
        this.answer = this.faq.answer;
        this.status = this.faq.status;
      }
    },
    methods: {
      ...mapActions({
        updateFAQ: "appData/updateFAQ",
      }),
      checkMaxLength(event, fieldName, maxLength) {
        if (
          event.target.value.length > maxLength &&
          event.inputType !== "deleteContentBackward" &&
          event.inputType !== "deleteContentForward"
        ) {
          event.preventDefault();
          this[fieldName] = event.target.value.slice(0, maxLength);
        } else {
          this[fieldName] = event.target.value;
        }
      },
      async validationForm() {
        const success = await this.$refs.faqEditFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
          const formData = {
            question: this.question,
            answer: this.answer,
            status: this.status,
          };
          const res = await this.updateFAQ({
            payload: formData,
            pk: this.faq.id,
          });
          if (res.status === 200) {
            this.$swal({
              title: "FAQ updated successfully",
              icon: "success",
            });
            this.reset();
            this.$nextTick(() => {
              this.$bvModal.hide("faq-edit-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      reset() {
        this.question = "";
        this.answer = "";
        this.status = "";
      },
      displayError(error) {
        console.error(error);
        this.$swal({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    },
  };
  </script>
  
  <style></style>
  